<template>
  <b-container fluid>
    <b-row class="d-block d-md-flex">
      <b-col v-for="(item, index) in statisticsList" :key="index">
        <VStatisticsCrad
          v-if="count"
          :title="item.title"
          :icon="item.icon"
          :count="item.count"
          :variant="item.variant"
          :bg="item.bg"
        >
        </VStatisticsCrad>
      </b-col>
    </b-row>
    <VBarChart
      v-if="visitors"
      title="أحصائيات زيارات المصرف"
      :data="visitors"
    ></VBarChart>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      statisticsList: [
        {
          title: "عدد المستخدمين",
          count: 0,
          icon: "people",
          variant: "#2D74FF",
          bg: "rgba(45, 116, 255, 0.1)",
        },
        {
          title: "زيارات المصرف",
          count: 0,
          icon: "vinyl",
          variant: "#F38D15",
          bg: "rgba(243, 141, 21, 0.1)",
        },
        {
          title: "الفروع",
          count: 0,
          icon: "bricks",
          variant: "#14C25A",
          bg: "rgba(20, 192, 90, 0.1)",
        },
        {
          title: "أجهزة الصرف",
          count: 0,
          icon: "credit-card",
          variant: "#E73152",
          bg: "rgba(231, 49, 82, 0.1)",
        },
      ],
    };
  },
  computed: {
    count() {
      return this.$store.state.banks.statistics.count;
    },
    visitors() {
      return this.$store.state.banks.statistics.bank_visits;
    },
  },
  methods: {
    getCountList() {
      this.statisticsList[0].count = this.count.users;
      this.statisticsList[1].count = this.count.visits;
      this.statisticsList[2].count = this.count.branches;
      this.statisticsList[3].count = this.count.atms;
    },
  },
  mounted() {
    this.$store.dispatch("banks/get").then(() => {
      this.getCountList();
    });
  },
};
</script>

<style></style>
